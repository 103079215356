<template>
  <v-card elevation="0" class="px-5 py-4">
    <v-progress-circular v-if="saveIsLoading" size="20" width="2" indeterminate color="primary" />
    <div class="text-h6 black--text font-weight-bold mb-5">
      {{ $t('personal.title_main_card') }}
    </div>

    <v-card-text class="pa-0">
      <v-skeleton-loader
        :loading="fetchIsLoading"
        type="paragraph, list-item-avatar, list-item-avatar, actions"
      >
        <v-container class="pa-0 mb-12 mb-lg-6" fluid>
          <v-row no-gutters>
            <v-col :class="['mb-4 mb-sm-5 order-sm-0 pr-sm-2 order-lg-0 mb-lg-0', { 'max-width-278': isMedium }]" cols="12" sm="6" lg="2">
              <c-text-field
                hide-details="auto"
                :value="name"
                :label="$t('personal.name')"
                height="46"
                clearable
                :error-messages="errors.name"
                @input="changeUserName($event)"
              />
            </v-col>
            <v-col class="mb-4 order-sm-2 pr-sm-2 order-lg-1 mb-lg-0" cols="12" sm="8" md="9" lg="5">
              <c-text-field
                disabled
                height="46"
                hide-details="auto"
                :value="email"
                :label="$t('personal.email')"
                :error-messages="errors.email"
              >
                <template #append>
                  <c-btn
                    icon
                    class="ml-2"
                    color="primary"
                    :icon-props="{
                      icon: '$config',
                      size: 17
                    }"
                    @click="emailDialogIsShown = true"
                  />
                </template>
              </c-text-field>
            </v-col>
            <v-col class="mb-4 order-sm-1 pl-sm-2 order-lg-2 mb-lg-0 pl-lg-0" cols="12" sm="6" lg="2">
              <c-text-field
                :class="[{ 'max-width-193': isMedium }]"
                hide-details="auto"
                :value="phone"
                :placeholder="$t('personal.enter_your_number')"
                :label="$t('personal.phone')"
                clearable
                height="46"
                :error-messages="errors.phone"
                @input="changePhone($event)"
              />
            </v-col>
            <v-col class="order-sm-3 mt-sm-5 pl-sm-2 order-lg-3 mb-lg-0" cols="12" sm="4" md="3" lg="3">
              <c-btn
                block
                color="primary"
                outlined
                depressed
                large
                height="46"
                :loading="isLoading"
                :disabled="!!resultMessage"
                :label="$t('personal.change_password_button_text')"
                @click="passwordDialogIsShown = true"
              />
            </v-col>
          </v-row>
        </v-container>

        <profile-messengers />
      </v-skeleton-loader>
    </v-card-text>
    <template>
      <captcha
        ref="captcha"
        :error-messages="emailErrors.recaptcha"
        @verified="sendChangeEmail($event)"
      >
        <change-password-dialog
          :dialog-is-shown="passwordDialogIsShown"
          @close="passwordDialogIsShown = false"
          @agree="onAnswer()"
        />
      </captcha>
    </template>
    <change-email-dialog
      :dialog-is-shown="emailDialogIsShown"
      @open-chat="liveChatHandler"
      @close="emailDialogIsShown = false"
    />
  </v-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import personalRepository from '@/services/repositories/personal-repository.js'
  import Captcha from '@/components/Captcha.vue'
  import ProfileMessengers from './ProfileMessengers.vue'
  import ChangePasswordDialog from './ChangePasswordDialog.vue'
  import ChangeEmailDialog from './ChangeEmailDialog.vue'

  export default {
    name: 'MainProfile',
    components: {
      CTextField,
      ProfileMessengers,
      CBtn,
      ChangePasswordDialog,
      Captcha,
      ChangeEmailDialog
    },
    data() {
      return {
        passwordDialogIsShown: false,
        emailDialogIsShown: false,
        isLoading: false,
        resultMessage: '',
        emailErrors: {}
      }
    },
    computed: {
      ...mapState('personal', [
        'name',
        'phone',
        'email',
        'fetchIsLoading',
        'saveIsLoading',
        'errors'
      ]),
      ...mapState('settings', ['adNetwork']),
      isMobile() {
        return this.$vuetify.breakpoint.xs
      },
      isTablet() {
        return this.$vuetify.breakpoint.sm
      },
      isMedium() {
        return this.$vuetify.breakpoint.md
      },
      isDesktop() {
        return this.$vuetify.breakpoint.lgAndUp
      }
    },
    methods: {
      ...mapActions('personal', ['changeUserName', 'changePhone']),
      liveChatHandler() {
        const { liveChat } = this.adNetwork.settings
        if (liveChat.type === 'intercom') {
          this.$intercom.show()
        } else if (liveChat.type === 'tawk') {
          this.$tawk.toggleChat()
        } else if (liveChat.type === 'dashly') {
          this.$dashly.openChat()
        } else if (liveChat.type === 'crisp') {
          this.$crisp.toggleChat()
        }
      },
      onAnswer() {
        this.$refs.captcha.performVerifiedAction()
        this.passwordDialogIsShown = false
      },
      async sendChangeEmail(recaptcha) {
        this.isLoading = true
        this.emailErrors = {}
        try {
          this.resultMessage = await personalRepository.sendForgetPasswordEmail({
            email: this.email,
            recaptcha
          })
          this.$showSuccessNotification(this.resultMessage)
        } catch (error) {
          this.emailErrors = error
        }
        this.isLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .max-width-193 {
    max-width: 193px;
  }
  .max-width-278 {
    max-width: 278px;
  }
</style>
